'use client';

import { Typography, Container, Button } from '@mui/material';

/**
 * Top level error page which handles errors in pages without their own error.tsx
 * NOTE: This is NOT GlobalError and therefore is still wrapped in app/layout.tsx. As described
 * in NextJS docs this DOES NOT handle errors thrown in app/layout.tsx in the way that global-error.tsx does.
 * @see https://nextjs.org/docs/app/building-your-application/routing/error-handling#handling-errors-in-root-layouts
 * @param props - Component props
 * @returns Top level error component
 */
export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  return (
    <Container
      maxWidth="xl"
      component="main"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        m: 3,
      }}
    >
      <Typography variant="h1" gutterBottom>
        Something went wrong!
      </Typography>
      <Typography variant="h4" gutterBottom>
        {error.message}
      </Typography>
      <Button onClick={() => reset()}>Reload</Button>
    </Container>
  );
}
